import { Action } from "@reduxjs/toolkit";
import {
  FETCH_ERROR_RANGE_SUCCESS,
  FETCH_ERROR_LOG_SUCCESS,
  ErrorLogType,
} from "../actions/types";
import { ErrorResponse } from "../interfaces/ErrorInterface";

const err: ErrorResponse[] = [];
const initialState = {
  errors: err,
};

export default function (state = initialState, action: Action) {
  const { type, payload } = action as ErrorLogType;

  switch (type) {
    case FETCH_ERROR_LOG_SUCCESS:
      return { errors: payload };

    case FETCH_ERROR_RANGE_SUCCESS:
      return { errors: payload };

    default:
      return state;
  }
}
