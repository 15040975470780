import React, { useState} from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {  SubmitHandler } from "react-hook-form";
import { useAppDispatch } from "../app/hooks";
import { Form } from "../components/Form";
import { forgotPassword } from "../actions/password";
import { Alert, Link } from "@mui/material";

type FormValues = { 
  value1: string
  value2: string
};


export const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false)

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true);
    console.log(`http://${window.location.host}`)
    dispatch(forgotPassword(data.value1, `http://${window.location.host}`)).then(() => {
        setSuccess(true)
  })
      .catch(() => {
        setLoading(false);
      });
  };

  const props = {
    onSubmit: onSubmit,
    subject: "Reset password",
    icon: <LockOutlinedIcon />,
    linkText: "Go back to Sign In",
    linkRef:  "/signin",
    first: "email",
    second: null,
    loading: loading
  }

  return (<>
    {success ? <div className="success"><Alert severity="success" sx={{
          maxWidth: "500px",
          margin: "20px"
          }}>Password reset link succesfully sent!</Alert> 
              <Link href="/signin" variant="body2">
                Back to sign in page
              </Link>
          </div> :
    <Form {...props} />}
    </>
  )
};
