import { AppDispatch } from "../app/store";
import { loginUser, logoutUser } from "../services/auth-service";
import { clearMessage, setMessage } from "./message";
import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from "./types";

export const logIn =
  (username: string, password: string) => (dispatch: AppDispatch) => {
    return loginUser(username, password).then(
      (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
        dispatch(clearMessage());
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: LOGIN_FAIL,
          payload: { user: null },
        });

        if (error.response.status == 401) {
          dispatch(setMessage("wrong username or password"));
        } else {
          dispatch(setMessage(message));
        }

        return Promise.reject();
      }
    );
  };

export const logout = () => (dispatch: AppDispatch) => {
  logoutUser();
  dispatch({
    type: LOGOUT,
    payload: { user: null },
  });
  dispatch(setMessage("You have been logged out"));
};
