import { login } from "../api/endpoints";

export const loginUser = (username: string, password: string) => {
  return login(username, password).then((response) => {
    if (response.token) {
      localStorage.setItem("user", JSON.stringify(response));
    }
    return response;
  });
};

export const logoutUser = () => {
  localStorage.removeItem("user");
};
