import {
  FETCH_ERROR_LOG_FAIL,
  FETCH_ERROR_LOG_SUCCESS,
  FETCH_ERROR_RANGE_SUCCESS,
} from "./types";
import { getErrors, getErrorsRange } from "../api/endpoints";
import { AxiosResponseHeaders } from "axios";
import { setMessage } from "./message";
import { logout } from "./auth";
import { AppDispatch } from "../app/store";

export const fetchErrors = () => (dispatch: AppDispatch) => {
  return getErrors().then(
    (data) => {
      dispatch({
        type: FETCH_ERROR_LOG_SUCCESS,
        payload: data,
      });
      return Promise.resolve();
    },
    (error) => {
      sendError(error, dispatch);
    }
  );
};

export const fetchFilteredErrors =
  (start: string, end: string) => (dispatch: AppDispatch) => {
    return getErrorsRange(start, end).then(
      (data) => {
        dispatch({
          type: FETCH_ERROR_RANGE_SUCCESS,
          payload: data,
        });

        return Promise.resolve();
      },
      (error) => {
        sendError(error, dispatch);
      }
    );
  };

const sendError = (error: AxiosResponseHeaders, dispatch: AppDispatch) => {
  const message =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString();

  dispatch({
    type: FETCH_ERROR_LOG_FAIL,
    payload: [],
  });

  dispatch(setMessage(message));

  if (error.response.status == 401|| (error.response.status == 500 && message === "Access Denied")) {
    dispatch(logout());
  }

  return Promise.reject();
};
