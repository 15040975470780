import { Action } from "@reduxjs/toolkit";
import { SET_MESSAGE, CLEAR_MESSAGE, MessageType } from "../actions/types";

const initialState = {
  message: "",
};

export default function (state = initialState, action: Action) {
  const { type, payload } = action as MessageType;

  switch (type) {
    case SET_MESSAGE:
      return { message: payload };

    case CLEAR_MESSAGE:
      return { message: "" };

    default:
      return state;
  }
}
