function formatMonth(month: number) {
  const date = new Date();
  date.setMonth(month - 1);

  return date.toLocaleString("en-US", { month: "2-digit" });
}

function formatDay(day: number) {
  return day.toString().padStart(2, "0");
}

export function formatDate(string: number[]) {
  return string.map((date, i) => {
    if (i < 5) {
      switch (true) {
        case i === 0:
          return date.toString() + "-";
        case i === 1:
          return formatMonth(date) + "-";
        case i === 2:
          return formatDay(date) + " ";
        case i == 4:
          return date.toString() + " ";
        default:
          return date.toString() + ":";
      }
    }
    return null;
  });
}
