import React, { JSXElementConstructor, ReactElement } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useForm, SubmitHandler } from "react-hook-form";
import { useAppSelector } from "../app/hooks";
import { CircularProgress ,Alert, SvgIconTypeMap } from "@mui/material";

type FormValues = { 
  value1: string
  value2: string
};

interface props {
    onSubmit: SubmitHandler<FormValues>,
    first: string
    second: string | null
    subject: string
    linkText: string
    linkRef: string
    icon: ReactElement<string, JSXElementConstructor<SvgIconTypeMap>>
    loading: boolean
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#8b8e66",
    },
  },
});

export const Form: React.FC<props> = ({...props}: props) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {value1: "", value2: ""}
  });

  const { message } = useAppSelector((state) => state.message);


  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#e44e56" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {props.subject}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(props.onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              {...register("value1", { required: true })}
              required
              fullWidth
              id="value1"
              type={props.first}
              label={props.first}
              name="value1"
              autoComplete={props.first}
              autoFocus
            />

            {props.second &&
            <TextField
              {...register("value2", { required: true })}
              margin="normal"
              required
              fullWidth
              type={props.second}
              name="value2"
              label={props.second}
              id="value2"
              autoComplete="current-${props.second}"
            />
}
            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={props.loading}
            >
              {props.loading && (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              )}
              {props.subject}
            </Button>
            {message && <Alert severity="error">{message}</Alert>}
            <Grid container>
            {props.linkText &&
              <Grid item xs>
                <Link href={props.linkRef} variant="body2">
                  {props.linkText}
                </Link>
              </Grid>}
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};
