import React from "react";
import { Footer } from "../components/Footer";
import Navbar from "../components/Navbar";

interface ParentCompProps {
  childComp?: React.ReactNode;
}

export const Content: React.FC<ParentCompProps> = ({
  childComp,
}: ParentCompProps) => {
  return (
    <div className="content">
      <Navbar />
      {childComp}
      <Footer />
    </div>
  );
};
