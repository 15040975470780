import React, { useState, useEffect } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router-dom";
import { SubmitHandler } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { logIn } from "../actions/auth";
import { Form } from "../components/Form";

type FormValues = { 
  value1: string
  value2: string
};

export const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useAppSelector((state) => state.auth);

  const onSubmitForm: SubmitHandler<FormValues> = async (data) => {
    setLoading(true);
    dispatch(logIn(data.value1, data.value2))
      .then(() => {
        navigate("/");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, []);

  const props = {
    onSubmit: onSubmitForm,
    subject: "Sign In",
    icon: <LockOutlinedIcon />,
    linkRef: "/forgot_password",
    linkText:  "Forgot password?",
    first: "email",
    second: "password",
    loading: loading
  }

  return (
    <Form {...props} />
  )
};
