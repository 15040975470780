import React, { useMemo, Dispatch, SetStateAction, useEffect} from "react";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { ErrorResponse } from "../interfaces/ErrorInterface";
import "../styles/Error.css";
import { formatDate } from "../utils/DateFormatting";
import { Checkbox } from "@mui/material";
import { updateHandled } from "../api/endpoints";

interface ErrorResponseProps {
  responses: ErrorResponse[];
  setTriggerUpdate: Dispatch<SetStateAction<boolean>>;
  triggerUpdate: boolean;
}




const Table: React.FC<ErrorResponseProps> = ({
  responses, setTriggerUpdate, triggerUpdate}: ErrorResponseProps) => {

    useEffect(() => {
      setTriggerUpdate(true)
    }, [triggerUpdate])

   //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<ErrorResponse>[]>(
    () => [


      {
        accessorKey: "fileName",
        header: "Filename",
      },
      {
        accessorKey: "errorMessage",
        header: "Error Message",
      },
      {
        accessorKey: "createdDate",
        header: "Date created",
        Cell: ({ cell }) => (
          <span>{formatDate(cell.getValue<number[]>())}</span>
        ),
      },
      {
        accessorKey: "costCenter",
        header: "Cost center",
      },
      {
        accessorKey: "digitalMail",
        header: "Digital mail",
        filterFn: 'equals',
        filterSelectOptions: [
          { text: 'yes', value: 'true' },
          { text: 'no', value: 'false' }
        ],
        filterVariant: 'select',
        Cell: ({ cell }) => (
          <span>{cell.getValue<boolean>() ? "yes" : "no"}</span>
        ),
      },
      {
        accessorKey: "handled",
        header: "Handled",
        filterFn: 'equals',
        filterSelectOptions: [
          { text: 'yes', value: 'true' },
          { text: 'no', value: 'false' }
        ],
        filterVariant: 'select',
        Cell: ({ cell }) => (
          <Checkbox checked={cell.row.original.handled } onChange={() => { 
            updateHandled(cell.row.original.errorId, !cell.row.original.handled).then(() => {setTriggerUpdate(false)})
          }}></Checkbox>
        )
      }
    ],
    []
  );

  return (
    <div className="table-wrapper">
      <MaterialReactTable
       
        muiTableHeadCellProps={{
          //simple styling with the `sx` prop, works just like a style prop in this example
          sx: {
            fontWeight: "400",
            fontSize: "16px",
            fontFamily:
              '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
          },
        }}
        muiTableBodyCellProps={{
          //simple styling with the `sx` prop, works just like a style prop in this example
          sx: {
            fontWeight: "300",
            fontSize: "12px",
            fontFamily: '"Verdana","Geneva",sans-serif',
          },
        }}
        columns={columns}
        data={responses}
      />
    </div>
  );
};

export default Table;
