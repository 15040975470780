import {  sendPasswordResetLink } from "../api/endpoints";
import { AppDispatch } from "../app/store";
import { clearMessage, setMessage } from "./message";
import { SENT_RESET_LINK_SUCCESS, SENT_RESET_LINK_FAIL } from "./types";

export const forgotPassword =
  (email: string, location: string) => (dispatch: AppDispatch) => {
    return sendPasswordResetLink(email, location).then(
      () => {
        dispatch({
          type: SENT_RESET_LINK_SUCCESS
        });
        dispatch(clearMessage());
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: SENT_RESET_LINK_FAIL
        });

        dispatch(setMessage(message));

        return Promise.reject();
      }
    );
  };


  
