import React from "react";
import { ErrorOverview } from "../pages/ErrorOverview";
import { Routes, Route } from "react-router-dom";
import { SignIn } from "../pages/SignIn";
import { Content } from "../pages/Content";
import { useCallback } from "react";
import { logout } from "../actions/auth";
import { useAppDispatch } from "./hooks";
import { ForgotPassword } from "../pages/ForgotPassword";

import "../styles/App.css";

function App() {
  const dispatch = useAppDispatch();

  useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <Routes>
      {/* The Switch decides which component to show based on the current URL.*/}
      <Route
        path="/"
        element={<Content childComp={<ErrorOverview />} />}
      />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route path="/signin" element={<SignIn />}/>
    </Routes>
  );
}

export default App;
