import axios from "axios";
import { ErrorResponse } from "../interfaces/ErrorInterface";
import authHeader from "../services/auth-header";

export const login = async (username: string, password: string) => {
  const { data } = await axios.post(`/api/authenticate`, {
    username,
    password,
  });
  return data;
};

export const getErrors = async (): Promise<ErrorResponse[]> => {
  const { data } = await axios.get(`/api/errors`, {
    headers: {
      "Access-Control-Allow-Origin": true,
      Authorization: authHeader().Authorization,
    },
  });
  return data;
};


export const updateHandled = async (errorId: number, handled: boolean): Promise<boolean> => {
  const { data } = await axios.post(`/api/errors/update`, {errorId,
    handled}, {
    headers: {
      "Access-Control-Allow-Origin": true,
      Authorization: authHeader().Authorization,
    },

   
  });
  return data;
};

export const getErrorsRange = async (
  start: string,
  end: string
): Promise<ErrorResponse[]> => {
  const { data } = await axios.get(`/api/errors/${start}/${end}`, {
    headers: {
      "Access-Control-Allow-Origin": true,
      Authorization: authHeader().Authorization,
    },
  });
  return data;
};

export const sendPasswordResetLink = async (email: string, location: string) => {
  const { data } = await axios.post(`/api/forgot_password`, {
    email,
    location
  });
  return data;
}


