import { ErrorResponse } from "../interfaces/ErrorInterface";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SENT_RESET_LINK_SUCCESS = "SENT_RESET_LINK_SUCCESS"
export const SENT_RESET_LINK_FAIL = "SENT_RESET_LINK_FAIL"

export const FETCH_ERROR_LOG_SUCCESS = "FETCH_ERROR_LOG_SUCCESS";
export const FETCH_ERROR_RANGE_SUCCESS = "FETCH_ERROR_RANGE_SUCCESS";
export const FETCH_ERROR_LOG_FAIL = "FETCH_ERROR_LOG_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export type MessageType =
  | {
      type: "SET_MESSAGE";
      payload: string;
    }
  | {
      type: "CLEAR_MESSAGE";
      payload: string;
    };

export type AuthType =
  | {
      type: "LOGIN_SUCCESS";
      payload: { user: string };
    }
  | {
      type: "LOGIN_FAIL";
      payload: { user: string | null };
    }
  | {
      type: "LOGOUT";
      payload: { user: string | null };
    };

export type ErrorLogType =
  | {
      type: "FETCH_ERROR_RANGE_SUCCESS";
      payload: ErrorResponse[] | [];
    }
  | {
      type: "FETCH_ERROR_LOG_SUCCESS";
      payload: ErrorResponse[] | [];
    }
  | {
      type: "FETCH_ERROR_LOG_FAIL";
      payload: ErrorResponse[] | [];
    };
