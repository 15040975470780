import React, { useEffect, useState } from "react";
import Table from "../components/Table";
import dayjs, { Dayjs } from "dayjs";
import { Button } from "@mui/material";
import { Filter } from "../components/Filter";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "../styles/Error.css";
import { fetchErrors, fetchFilteredErrors } from "../actions/errrorlog";

export const ErrorOverview: React.FC = () => {
  const [start, setStart] = useState<Dayjs | null>(null);
  const [end, setEnd] = useState<Dayjs | null>(null);
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const { message } = useAppSelector((state) => state.message);
  const { errors } = useAppSelector((state) => state.errors);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/signin");
    }
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/signin");
    }
  }, [isLoggedIn, triggerUpdate]);

  useEffect(() => {
    if (start && end) {
      dispatch(
        fetchFilteredErrors(
          start.format("YYYY-MM-DD"),
          end.format("YYYY-MM-DD")
        )
      );
    } else {
      dispatch(fetchErrors());
    }
  }, [triggerUpdate]);

  function handleFilter() {
    if (!start && end) {
      setStart(dayjs());
    }
    if (!end && start) {
      setEnd(dayjs());
    }

    return triggerUpdate ? setTriggerUpdate(() => false) : setTriggerUpdate(() => true);
  }

  function handleClearFilter() {
    setStart(null);
    setEnd(null);
    return triggerUpdate ? setTriggerUpdate(false) : setTriggerUpdate(true);
  }

  return (
    <div className="error-overview">
      <div className="filter">
        <Filter type="Start" value={start} setValue={setStart} />
        <p style={{ fontSize: "18px", margin: "auto 10px auto 10px" }}>to</p>
        <Filter type="End" value={end} setValue={setEnd} />
        <div className="button-wrapper">
          <Button variant="outlined" onClick={() => handleFilter()}>
            filter
          </Button>
        </div>
        <div className="button-wrapper">
          <Button variant="outlined" onClick={() => handleClearFilter()}>
            clear
          </Button>
        </div>
      </div>
      {message && <Alert severity="error">{message}</Alert>}
  
        <Table responses={errors} setTriggerUpdate={setTriggerUpdate} triggerUpdate={triggerUpdate}/>
    </div>
  );
};
