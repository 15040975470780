import React from "react";
import "../styles/Error.css";
import Link from "@mui/material/Link";
import { Typography } from "@mui/material";

type ContainerProps = {
  children: React.ReactNode; //👈 children prop typr
};

export const Footer: React.FC = () => {
  function Copyright(props: ContainerProps) {
    return (
      <Typography variant="body2" color="white" align="center" {...props} >
        {"Copyright © "}
        <Link color="inherit" href="https://acceptus.se/">
          Acceptus
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  return (
    <div className="footer">
      <Copyright> </Copyright>
    </div>
  );
};
